import { CalendarIcon } from 'lucide-react';

import { Button } from '@/components/ui/button';
import {
  Calendar,
  CalendarRangeProps,
  CalendarSingleProps,
} from '@/components/ui/calendar';
import { FormItem } from '@/components/ui/form';
import { Label } from '@/components/ui/label';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { cn } from '@/lib/utils';
import { Temporal } from '@js-temporal/polyfill';
import {
  TZ_EUROPE_LONDON,
  formatDateForTracking,
  isToday,
  plainDateToJSDate,
} from '@packfleet/datetime';
import { format } from 'date-fns';
import { X } from 'lucide-react';

function formateDateRange(
  date:
    | {
        from: Temporal.PlainDate | undefined;
        to?: Temporal.PlainDate | undefined;
      }
    | undefined,
) {
  if (!date?.from) {
    return 'Pick a date';
  }

  if (!date.to) {
    return `${format(plainDateToJSDate(date.from), 'LLL dd, y')}`;
  }

  // If the distance between the two dates is more than 7 days, show the full date range
  if (isToday(date.to, TZ_EUROPE_LONDON)) {
    const distance = date.to.since(date.from).days;

    if (distance <= 90) {
      return `Last ${distance} days`;
    }
  }

  return `${format(plainDateToJSDate(date.from), 'LLL dd, y')} - ${format(
    plainDateToJSDate(date.to),
    'LLL dd, y',
  )}`;
}

export function CalendarInput({
  label,
  placeholder = 'Any date',
  selected,
  timezone,
  onSelect,
  allowEmpty = true,
  children,
  ...props
}: CalendarSingleProps & {
  label: string;
  placeholder?: string;
  allowEmpty?: boolean;
  children?: React.ReactNode;
}) {
  return (
    <FormItem className="flex flex-col">
      <Label>{label}</Label>
      <div className="flex gap-2 items-center">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className={cn(
                'w-full pl-3 text-left font-normal',
                !selected && 'text-muted-foreground',
              )}
            >
              {selected ? (
                formatDateForTracking(selected, timezone, { lowercase: false })
              ) : (
                <span>{placeholder}</span>
              )}
              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            {children}
            <Calendar
              mode="single"
              selected={selected}
              onSelect={onSelect}
              initialFocus
              timezone={timezone}
              {...props}
            />
          </PopoverContent>
        </Popover>
        {selected && allowEmpty ? (
          <Button
            variant="ghost"
            onClick={() => onSelect?.(undefined)}
            className="h-8 px-2 lg:px-3"
          >
            Clear
            <X />
          </Button>
        ) : null}
      </div>
    </FormItem>
  );
}

export function CalendarRangeInput({
  label,
  placeholder = 'Any date',
  selected,
  timezone,
  onSelect,
  allowEmpty = true,
  children,
  ...props
}: CalendarRangeProps & {
  label?: string;
  placeholder?: string;
  allowEmpty?: boolean;
  children?: React.ReactNode;
}) {
  return (
    <FormItem className="flex flex-col">
      {label && <Label>{label}</Label>}
      <div className="flex gap-2 items-center">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className={cn(
                'w-[240px] pl-3 text-left font-normal',
                !selected && 'text-muted-foreground',
              )}
            >
              {selected ? (
                formateDateRange(selected)
              ) : (
                <span>{placeholder}</span>
              )}
              <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <div className="px-3">{children}</div>
            <Calendar
              mode="range"
              selected={selected}
              onSelect={onSelect}
              initialFocus
              timezone={timezone}
              {...props}
            />
          </PopoverContent>
        </Popover>
        {selected && allowEmpty ? (
          <Button
            variant="ghost"
            onClick={() => onSelect?.(undefined)}
            className="h-8 px-2 lg:px-3"
          >
            Clear
            <X />
          </Button>
        ) : null}
      </div>
    </FormItem>
  );
}
