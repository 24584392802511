/* eslint-disable @typescript-eslint/ban-types */

import { ApolloError } from '@apollo/client';
import { useRouter } from 'next/router';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import React from 'react';
import { useCurrentUserQuery } from '../generated/graphql';
import { Routes, route } from './routes';

export enum NoOrganizationBehaviour {
  RedirectToSignUp = 'redirect-to-signup',
  RedirectToCreateOrganization = 'redirect-to-create-organization',
  Allow = 'allow',
}

export enum UserState {
  Unauthenticated = 'unauthenticated',
  NeedsOrganization = 'needs-organization',
  NeedsOnboarding = 'needs-onboarding',
  NoActionRequired = 'no-action-required',
}

export enum RedirectType {
  RequireAuthenticated = 'require-authenticated',
  RequireUnauthenticated = 'require-unauthenticated',
  RequireNeedsOnboarding = 'require-needs-onboarding',
  RequireNeedsOrganization = 'require-needs-organization',
}

// useRedirectForUserState is a hook that redirects the user based on their authentication and onboarding state.
// It can be used in sign-up to ensure the user isn't already logged in, or in authenticated parts of the app to redirect
// the user to the login page if they're not already logged in. For "partially signed up" users, like ones with no organization set
// or that haven't completed onboarding, it redirects them to the appropriate page to complete setup.
// It's kind of a hack
// around next.js's limitations on redirecting from the getServerSideProps function.
function useRedirectForUserState({
  noOrganizationBehaviour,
  redirectType,
}: {
  noOrganizationBehaviour: NoOrganizationBehaviour;
  redirectType: RedirectType;
}): {
  firstLoad?: boolean;
  redirected?: boolean;
} {
  const router = useRouter();
  const { loading, error, userState } = useGetUserState();
  if (loading) {
    return { firstLoad: true };
  }
  if (error) {
    // Don't redirect if there's an error when fetching the user, as they may still be logged in
    return { redirected: false };
  }
  const basePath = router.asPath.split('?')[0];

  let redirectUrl;
  switch (userState) {
    case UserState.NoActionRequired:
      if (
        redirectType === RedirectType.RequireUnauthenticated ||
        redirectType === RedirectType.RequireNeedsOnboarding ||
        redirectType === RedirectType.RequireNeedsOrganization
      ) {
        redirectUrl = route(Routes.appDashboard);
      }
      break;
    case UserState.Unauthenticated:
      if (redirectType === RedirectType.RequireAuthenticated) {
        redirectUrl = route(Routes.login, { redirect_uri: router.asPath });
      }
      break;
    case UserState.NeedsOrganization:
      switch (noOrganizationBehaviour) {
        case NoOrganizationBehaviour.RedirectToSignUp:
          // This path is usually taken if the new-signup-flow feature flag is disabled.
          // TODO: Remove this when the new-signup-flow feature flag is fully rolled out
          if (basePath === Routes.createOrganization) {
            // Don't redirect if we're on the "other" version of this page, as this can cause a redirect
            // loop while feature flags are being loaded
            break;
          }
          redirectUrl = route(Routes.signUp, { redirect_uri: router.asPath });
          break;
        case NoOrganizationBehaviour.RedirectToCreateOrganization:
          // This path is usually taken if the new-signup-flow feature flag is enabled
          redirectUrl = route(Routes.createOrganization);
          break;
        case NoOrganizationBehaviour.Allow:
          break;
      }
      break;
    case UserState.NeedsOnboarding:
      redirectUrl = route(Routes.onboarding);
      break;
  }

  const baseRedirectPath = redirectUrl?.split('?')[0];
  if (redirectUrl && basePath !== baseRedirectPath) {
    if (typeof window !== 'undefined') {
      void router.replace(redirectUrl);
    }
    return { redirected: true };
  }

  return { redirected: false };
}

export function requireAuthentication<T extends {}>(
  Cmp: React.ComponentType<T> & {
    getInitialProps?: any;
  },
  opts?: { noOrganizationBehaviour: NoOrganizationBehaviour },
) {
  return wrapComponentWithUserStateRedirect(Cmp, {
    redirectType: RedirectType.RequireAuthenticated,
    noOrganizationBehaviour: opts?.noOrganizationBehaviour,
  });
}

export function requireUnauthenticated<T extends {}>(
  Cmp: React.ComponentType<T> & {
    getInitialProps?: any;
  },
  opts?: { noOrganizationBehaviour: NoOrganizationBehaviour },
) {
  return wrapComponentWithUserStateRedirect(Cmp, {
    redirectType: RedirectType.RequireUnauthenticated,
    noOrganizationBehaviour: opts?.noOrganizationBehaviour,
  });
}

export function requireHasNoOrganization<T extends {}>(
  Cmp: React.ComponentType<T> & {
    getInitialProps?: any;
  },
) {
  return wrapComponentWithUserStateRedirect(Cmp, {
    redirectType: RedirectType.RequireUnauthenticated,
    noOrganizationBehaviour:
      NoOrganizationBehaviour.RedirectToCreateOrganization,
  });
}

export function requireNeedsOnboarding<T extends {}>(
  Cmp: React.ComponentType<T> & {
    getInitialProps?: any;
  },
  opts?: { noOrganizationBehaviour: NoOrganizationBehaviour },
) {
  return wrapComponentWithUserStateRedirect(Cmp, {
    redirectType: RedirectType.RequireNeedsOnboarding,
    noOrganizationBehaviour: opts?.noOrganizationBehaviour,
  });
}

function wrapComponentWithUserStateRedirect<T extends {}>(
  Cmp: React.ComponentType<T> & {
    getInitialProps?: any;
  },
  opts: {
    redirectType: RedirectType;
    noOrganizationBehaviour?: NoOrganizationBehaviour;
  },
) {
  const Wrapped = (props: T) => {
    const defaultNoOrganizationBehaviour = useDefaultNoOrganizationBehaviour();
    const { redirected } = useRedirectForUserState({
      noOrganizationBehaviour:
        opts.noOrganizationBehaviour ?? defaultNoOrganizationBehaviour,
      redirectType: opts.redirectType,
    });
    if (redirected) {
      return null;
    }
    return <Cmp {...props} />;
  };
  Wrapped.displayName = `wrapComponentWithUserStateRedirect(${
    Cmp.displayName || Cmp.name
  }, ${opts.redirectType})`;
  Wrapped.getInitialProps = Cmp.getInitialProps;
  return Wrapped;
}

function useGetUserState(): {
  loading?: boolean;
  error?: ApolloError | undefined;
  userState?: UserState;
} {
  const { data, loading, error } = useCurrentUserQuery();
  if (loading) {
    return { loading };
  }
  if (error) {
    // Don't redirect if there's an error when fetching the user, as they may still be logged in
    return { error };
  }

  const user = data?.currentUser?.user;
  const onboardingStage = data?.readOnboardingStatus?.onboardingStatus?.stage;
  const isLoggedIn = user != null;
  const hasOrganization = user?.organization != null;
  const needsOnboarding =
    onboardingStage != null &&
    data?.readOnboardingStatus?.onboardingStatus?.onboardingCompletedAt ==
      null &&
    data?.readOnboardingStatus?.onboardingStatus?.onboardingSkippedAt == null;

  if (!isLoggedIn) {
    return { userState: UserState.Unauthenticated };
  } else if (!hasOrganization) {
    return { userState: UserState.NeedsOrganization };
  } else if (needsOnboarding) {
    return { userState: UserState.NeedsOnboarding };
  }

  return { userState: UserState.NoActionRequired };
}

function useDefaultNoOrganizationBehaviour() {
  const newSignupEnabled = useFeatureFlagEnabled('new-onboarding-flow');
  if (newSignupEnabled) {
    // New sign-up creates the organization separately to the user sign-up during a new flow.
    return NoOrganizationBehaviour.RedirectToCreateOrganization;
  }
  // Old sign-up creates the organization as part of the user sign-up.
  return NoOrganizationBehaviour.RedirectToSignUp;
}
