import cs from 'classnames';
import { ReactNode } from 'react';
import { IconType } from 'react-icons';
import Link from '../link/Link';

export type Props = {
  href: string;
  icon: IconType;
  isActive?: boolean;
  children?: ReactNode;
};

const SidebarButton = ({ href, icon: Icon, isActive, children }: Props) => {
  return (
    <div>
      <Link
        unstyled
        href={href}
        className={cs('flex w-full items-center p-4', {
          'hover:bg-faded': !isActive,
          'text-bold bg-brandBright text-brandDark': isActive,
        })}
      >
        <Icon />

        <span className="ml-2">{children}</span>
      </Link>
    </div>
  );
};

export default SidebarButton;
