'use client';

import { ChevronLeft, ChevronRight } from 'lucide-react';
import * as React from 'react';
import {
  DateRange,
  DayPicker,
  DayPickerRangeProps,
  DayPickerSingleProps,
} from 'react-day-picker';

import { buttonVariants } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Temporal } from '@js-temporal/polyfill';
import {
  toJSDateUTC,
  toOptionalPlainDate,
  toPlainDate,
} from '@packfleet/datetime';

type TemporalDateRange = {
  from: Temporal.PlainDate | undefined;
  to?: Temporal.PlainDate | undefined;
};

type CalendarPrimitiveProps = React.ComponentProps<typeof DayPicker>;

export type CalendarSingleProps = Omit<
  DayPickerSingleProps,
  'selected' | 'onSelect' | 'mode'
> & {
  timezone: string;
  mode?: 'single';
  selected?: Temporal.PlainDate;
  onSelect?: (date: Temporal.PlainDate | undefined) => void;
};

export type CalendarRangeProps = Omit<
  DayPickerRangeProps,
  'selected' | 'onSelect' | 'mode'
> & {
  mode?: 'range';
  timezone: string;
  selected?: TemporalDateRange;
  onSelect?: (date: TemporalDateRange | undefined) => void;
};

export type CalendarProps = CalendarSingleProps | CalendarRangeProps;

function CalendarPrimitive({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarPrimitiveProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          buttonVariants({ variant: 'outline' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100',
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell:
          'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full mt-2',
        cell: 'h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-9 w-9 p-0 font-normal aria-selected:opacity-100',
        ),
        day_range_end: 'day-range-end',
        day_selected:
          'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
        day_today: 'bg-accent text-accent-foreground',
        day_outside:
          'day-outside text-muted-foreground aria-selected:bg-accent/50 aria-selected:text-muted-foreground',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle:
          'aria-selected:bg-accent aria-selected:text-accent-foreground',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeft className="h-4 w-4" />,
        IconRight: () => <ChevronRight className="h-4 w-4" />,
      }}
      {...props}
    />
  );
}

function Calendar({
  timezone,
  selected,
  mode,
  onSelect,
  ...props
}: CalendarProps) {
  if (mode === 'single') {
    return (
      <CalendarPrimitive
        {...props}
        mode={mode}
        selected={
          // We can't use `plainDateToJSDate` here, because DayPicker does internal date comparison
          // to figure out which day should be highlighted, and this doesn't work if you pass it a date
          // with a time set to midnight. If we set it to 12:00 instead, it will work in any timezone.
          // In other words, the safety of Temporal can only take us this far.
          selected
            ? toJSDateUTC(
                selected.toZonedDateTime({
                  timeZone: timezone,
                  plainTime: '12:00',
                }),
              )
            : undefined
        }
        onSelect={
          onSelect
            ? (date: Date | undefined) => {
                if (date) {
                  onSelect(toPlainDate(date));
                }
              }
            : undefined
        }
      />
    );
  } else if (mode === 'range') {
    return (
      <CalendarPrimitive
        {...props}
        mode={mode}
        selected={
          // We can't use `plainDateToJSDate` here, because DayPicker does internal date comparison
          // to figure out which day should be highlighted, and this doesn't work if you pass it a date
          // with a time set to midnight. If we set it to 12:00 instead, it will work in any timezone.
          // In other words, the safety of Temporal can only take us this far.
          selected
            ? {
                from: selected.from
                  ? toJSDateUTC(
                      selected.from.toZonedDateTime({
                        timeZone: timezone,
                        plainTime: '12:00',
                      }),
                    )
                  : undefined,
                to: selected.to
                  ? toJSDateUTC(
                      selected.to.toZonedDateTime({
                        timeZone: timezone,
                        plainTime: '12:00',
                      }),
                    )
                  : undefined,
              }
            : undefined
        }
        onSelect={
          onSelect
            ? (dateRange: DateRange | undefined) => {
                if (dateRange) {
                  onSelect({
                    from: toOptionalPlainDate(dateRange.from),
                    to: toOptionalPlainDate(dateRange.to),
                  });
                }
              }
            : undefined
        }
      />
    );
  }

  return null;
}

Calendar.displayName = 'Calendar';

export { Calendar };
