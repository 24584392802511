import {
  ExternalCarrierPayloadFragment,
  useListAvailableExternalCarriersQuery,
} from '@/generated/graphql';
import { useCurrentUser } from '@/hooks/useCurrentUser';

export const PACKFLEET_CARRIER: ExternalCarrierPayloadFragment = {
  id: '',
  name: 'Packfleet',
  carrierCode: 'packfleet',
};

export const useListCarriers = () => {
  const organization = useCurrentUser()?.organization;

  const { data: availableExternalCarriersData, loading } =
    useListAvailableExternalCarriersQuery({
      skip: !organization?.externalShipmentManagementEnabled,
    });

  const carriers = [PACKFLEET_CARRIER].concat(
    availableExternalCarriersData?.listAvailableExternalCarriers
      ?.externalCarriers || [],
  );

  return { carriers, loading };
};
